<template>
    <div class="notifications-view">
        <PreviewComponent
            :expand="instantExpanded"
            title="Details"
            lead="This email notification will be sent upon every single response."
        >
            <template #header>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            markdown="instant-notifications"
                            external-link="https://www.surveylegend.com/user-guide/instant-survey-notifications/"
                        />
                        <span>Instant notifications</span>
                    </RowComponent>
                    <template v-if="!survey.hideSubmitButton">
                        <ToggleComponent
                            v-model="surveyNotificationSettings.onEveryResponse"
                            prevent
                            @change="handleChange($event, 'onEveryResponse')"
                        />
                    </template>
                    <template v-else>
                        <PopoverComponent
                            v-model:visible="visibleInstantPopover"
                            width="300px"
                            placement="bottom-end"
                            :close-delay="0"
                        >
                            <template #trigger>
                                <ToggleComponent
                                    :value="false"
                                    locked
                                    @mouseenter="visibleInstantPopover = true"
                                    @mouseleave="visibleInstantPopover = false"
                                />
                            </template>
                            <p>
                                You <strong>cannot</strong> enable this when submit button is
                                hidden.
                            </p>
                        </PopoverComponent>
                    </template>
                </RowComponent>
            </template>
            <template #options>
                <LoadingComponent
                    v-if="loading"
                    stroke-color="#579ae6"
                    overlay="rgba(255, 255, 255, 0.8)"
                />
                <span class="preview-component__title">Email sent to</span>
                <RowComponent justify="space-between" align="center">
                    <InputComponent :value="user.user_email" readonly>
                        <template #append>
                            <i class="input-component__icon--lock" />
                        </template>
                    </InputComponent>
                </RowComponent>
                <RowComponent v-for="(notification, index) in instantNotifications" :key="index"
                    ><SaveComponent
                        v-model="notification.email"
                        :name="`email-${index}`"
                        :autocomplete="`email-${index}`"
                        @save="handleEmailSave($event, notification, index)"
                        @delete="handleEmailDelete(notification, index)"
                /></RowComponent>
                <RowComponent
                    ><ButtonComponent
                        class="button-component--add"
                        type="success"
                        shadow
                        :disabled="maxAllowedEmails('instant')"
                        @click="handleEmailAdd('instant')"
                    >
                        <i class="button-component--add__icon" />
                        <span>Add new email</span></ButtonComponent
                    ></RowComponent
                >
                <DividerComponent />
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            markdown="include-pdf"
                            external-link="https://www.surveylegend.com/user-guide/instant-survey-notifications/#include-pdf-link"
                        />
                        <span>Include PDF link</span>
                    </RowComponent>
                    <ToggleComponent
                        v-model="surveyNotificationSettings.includePdfLink"
                        prevent
                        short
                        @change="handleChange($event, 'includePdfLink')"
                    />
                </RowComponent>
            </template>
            <template #preview>
                <CardComponent>
                    <template #header>
                        <ImageComponent src="/img/notifications/instant.png" />
                    </template>
                    <p>Your survey "{{ survey.name }}" has received a new response:</p>
                    <table>
                        <tr>
                            <td>Response ID</td>
                            <td>#</td>
                        </tr>
                        <tr>
                            <td>Started time</td>
                            <td>{{ startedTime }}</td>
                        </tr>
                        <tr>
                            <td>Time spent</td>
                            <td>00:00:00</td>
                        </tr>
                        <tr>
                            <td>Progress</td>
                            <td>0% (0 out of {{ totalItems }})</td>
                        </tr>
                    </table>

                    <transition name="slide-down">
                        <ColumnComponent v-if="surveyNotificationSettings.includePdfLink">
                            <ButtonComponent type="warning"
                                >Download response as PDF</ButtonComponent
                            >
                            <p>OR</p>
                        </ColumnComponent>
                    </transition>
                    <ButtonComponent type="warning">See all responses</ButtonComponent>
                </CardComponent>
            </template>
        </PreviewComponent>
        <PreviewComponent
            :expand="summaryExpanded"
            title="Details"
            lead="This email notification will be sent upon certain intervals of responses."
        >
            <template #header>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            markdown="summary-notifications"
                            external-link="https://www.surveylegend.com/user-guide/survey-summary-notifications/"
                        />
                        <span>Summary notifications</span>
                    </RowComponent>
                    <template v-if="!survey.hideSubmitButton">
                        <ToggleComponent
                            v-model="surveyNotificationSettings.enabled"
                            prevent
                            @change="handleChange($event, 'enabled')"
                        />
                    </template>
                    <template v-else>
                        <PopoverComponent
                            v-model:visible="visibleSummaryPopover"
                            width="300px"
                            placement="bottom-end"
                            :close-delay="0"
                        >
                            <template #trigger>
                                <ToggleComponent
                                    :value="false"
                                    locked
                                    @mouseenter="visibleSummaryPopover = true"
                                    @mouseleave="visibleSummaryPopover = false"
                                />
                            </template>
                            <p>
                                You <strong>cannot</strong> enable this when submit button is
                                hidden.
                            </p>
                        </PopoverComponent>
                    </template>
                </RowComponent>
            </template>
            <template #options>
                <LoadingComponent
                    v-if="loading"
                    stroke-color="#579ae6"
                    overlay="rgba(255, 255, 255, 0.8)"
                />
                <span class="preview-component__title">Email sent to</span>
                <RowComponent justify="space-between" align="center">
                    <InputComponent :value="user.user_email" readonly>
                        <template #append>
                            <i class="input-component__icon--lock" />
                        </template>
                    </InputComponent>
                </RowComponent>
                <RowComponent v-for="(notification, index) in summaryNotifications" :key="index"
                    ><SaveComponent
                        v-model="notification.email"
                        @save="handleEmailSave($event, notification, index)"
                        @delete="handleEmailDelete(notification, index)"
                /></RowComponent>
                <RowComponent
                    ><ButtonComponent
                        class="button-component--add"
                        type="success"
                        shadow
                        :disabled="maxAllowedEmails('summary')"
                        @click="handleEmailAdd('summary')"
                    >
                        <i class="button-component--add__icon" />
                        <span>Add new email</span></ButtonComponent
                    ></RowComponent
                >
            </template>
            <template #preview>
                <CardComponent>
                    <template #header>
                        <ImageComponent src="/img/notifications/summary.png" />
                    </template>
                    <p>
                        Congratulations! Your survey "{{ survey.name }}" has received 10 responses.
                    </p>
                    <ButtonComponent type="warning">See all responses</ButtonComponent>
                </CardComponent>
            </template>
        </PreviewComponent>
        <Teleport to="body">
            <UpgradeComponent
                ref="upgrade-survey-notifications"
                name="survey-notifications"
                image="survey-notifications.png"
                free-upgrade
            /><UpgradeComponent
                ref="upgrade-instant-notification-emails-addresses-business"
                name="instant-notification-emails-addresses-business"
                permission="instant-notification-emails-addresses"
                image="survey-notifications.png"
            />
            <UpgradeComponent
                ref="upgrade-instant-notification-emails-addresses-legendary"
                name="instant-notification-emails-addresses-legendary"
                permission="instant-notification-emails-addresses"
                image="survey-notifications.png"
            />
            <UpgradeComponent
                ref="upgrade-summary-notification-emails-addresses-business"
                name="summary-notification-emails-addresses-business"
                permission="summary-notification-emails-addresses"
                image="survey-notifications.png"
            />
            <UpgradeComponent
                ref="upgrade-summary-notification-emails-addresses-legendary"
                name="summary-notification-emails-addresses-legendary"
                permission="summary-notification-emails-addresses"
                image="survey-notifications.png"
            />
        </Teleport>
    </div>
</template>

<script>
import { format } from 'date-fns'
import AuthService from '@/services/auth-service'
import { db } from '@/firebase'
import { mapGetters, mapMutations } from 'vuex'

const surveys = db.ref('v2/survey_meta/')
const surveysNotificationSettings = db.ref('v2/survey_notification_settings/')
const surveysItems = db.ref('v2/survey_items/')

export default {
    name: 'Notifications',

    metaInfo: {
        title: 'Notifications'
    },

    data() {
        return {
            loading: false,
            survey: {},
            surveyNotificationSettings: {},
            surveyItems: {},
            visibleInstantPopover: false,
            visibleSummaryPopover: false
        }
    },

    computed: {
        ...mapGetters({
            user: 'auth/user',
            permissions: 'auth/permissions',
            instantNotifications: 'notifications/instantNotifications',
            summaryNotifications: 'notifications/summaryNotifications'
        }),

        instantExpanded() {
            return this.surveyNotificationSettings.onEveryResponse && !this.survey.hideSubmitButton
        },

        summaryExpanded() {
            return this.surveyNotificationSettings.enabled && !this.survey.hideSubmitButton
        },

        startedTime() {
            return format(new Date(), 'yyyy-MM-dd HH:mm:ss')
        },

        totalItems() {
            return Object.keys(this.surveyItems).length
        }
    },

    watch: {
        '$route.params.id': {
            immediate: true,
            handler(id) {
                this.$rtdbBind('survey', surveys.child(id))
                this.$rtdbBind('surveyNotificationSettings', surveysNotificationSettings.child(id))
                this.$rtdbBind('surveyItems', surveysItems.child(id))
            }
        }
    },

    async created() {
        this.loading = true

        await this.$store.dispatch('notifications/fetchNotifications', {
            survey_id: this.$route.params.id
        })

        this.loading = false
    },

    methods: {
        ...mapMutations({
            addNotification: 'notifications/ADD_NOTIFICATION',
            deleteNotification: 'notifications/DELETE_NOTIFICATION'
        }),

        maxAllowedEmails(type) {
            if (this.user.level && !this.user.level.startsWith('legendary')) return false

            const permissionCount = this.permissions[`${type}-notification-emails-addresses`]
            const totalNotifications = this[`${type}Notifications`].length

            return permissionCount === totalNotifications + 1
        },

        handleSave(data) {
            this.$firebaseRefs.surveyNotificationSettings.update(data)
        },

        async handleChange({ value }, toggle) {
            let permission
            let hasPermission

            const data = {}

            if (value) {
                switch (toggle) {
                    case 'onEveryResponse':
                        permission = 'survey-notifications'
                        hasPermission = this.permissions[permission]

                        break
                    default:
                        hasPermission = true
                }

                if (!hasPermission) {
                    this.track('open-upgrade-modal', permission)

                    const resolve = await this.$refs[`upgrade-${permission}`].open()

                    this.$refs[`upgrade-${permission}`].close()

                    if (resolve === 'free') {
                        window.location.href = `https://www.surveylegend.com/get-free-features/`
                    } else if (resolve) {
                        window.location.href = `https://www.surveylegend.com/upgrade?source=upgrade_modal&type=${permission}&level=${this.permissions.level}`
                    }

                    return
                } else if (permission) {
                    this.track('has-permission', permission)
                }
            }

            data[toggle] = value

            this.handleSave(data)
        },

        handleEmailSave(email, notification, index) {
            notification.email = email

            if (notification.id) {
                this.$store.dispatch('notifications/updateNotification', {
                    index,
                    notification
                })
            } else {
                this.$store.dispatch('notifications/addNotification', {
                    index,
                    notification
                })
            }
        },

        handleEmailDelete(notification, index) {
            if (notification.id) {
                this.$store.dispatch('notifications/deleteNotification', {
                    index,
                    notification
                })
            } else {
                this.deleteNotification({
                    index,
                    notification
                })
            }
        },

        async handleEmailAdd(type) {
            const permission = `${type}-notification-emails-addresses`
            const permissionCount = this.permissions[permission]
            const totalNotifications = this[`${type}Notifications`].length
            const { data: level } = await AuthService.getPermissionLevel({
                permission,
                count: permissionCount + 1
            })

            if (permissionCount !== -1 && totalNotifications + 1 >= permissionCount && level) {
                this.track('open-upgrade-modal', permission)

                const isConfirmed = await this.$refs[`upgrade-${permission}-${level}`].open()

                this.$refs[`upgrade-${permission}-${level}`].close()

                if (isConfirmed) {
                    window.location.href = `https://www.surveylegend.com/upgrade?source=upgrade_modal&type=${permission}&level=${this.permissions.level}`
                }

                return
            } else if (permission) {
                this.track('has-permission', permission)

                if (permissionCount > totalNotifications + 1) {
                    this.addNotification({
                        notification: {
                            survey_id: this.$route.params.id,
                            type,
                            email: ''
                        }
                    })
                }
            }
        },

        track(action, name) {
            this.$gtag.event(action, {
                event_category: name,
                event_label: this.permissions.level
            })
        }
    }
}
</script>

<style lang="scss">
.notifications-view {
    .loading-component {
        border-radius: 0 0 0 6px;
    }

    .preview-component {
        margin-top: 32px;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .preview-component__options {
        min-height: 848px;

        > .row-component {
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .input-component__append {
            display: flex;

            align-items: center;
        }

        .input-component__icon--lock {
            @include icon('~@/assets/svg/icons/lock.svg');

            display: inline-block;

            width: 16px;
            height: 16px;

            margin: -2px 11px 0;

            background-size: contain;
        }

        .button-component--add {
            display: flex;

            justify-content: space-between;

            width: 100%;

            padding: 11px 16px;

            border-radius: 4px;

            &:after {
                content: '';

                flex: 1;
            }
        }

        .button-component--add__icon {
            @include icon('~@/assets/svg/icons/plus.svg');

            display: inline-block;

            flex: 1;

            width: 20px;
            height: 20px;

            background-position: left center !important;
            background-size: contain;
        }
    }

    .preview-component__preview {
        .card-component {
            height: auto;
        }

        .card-component__header {
            padding: 0;
            border-bottom: 0;
        }

        .card-component__content {
            display: flex;
            flex-direction: column;
            align-items: center;

            padding: 30px;

            p {
                text-align: center;

                padding: 20px;
            }

            table {
                width: 100%;

                margin: 10px 0 40px;

                border: 1px solid #f2f2f2;
                border-collapse: collapse;

                tr {
                    border: 1px solid #f2f2f2;

                    &:nth-child(odd) {
                        background-color: #fafafa;
                    }
                }

                td {
                    padding: 12px 20px;

                    border: 1px solid #f2f2f2;

                    &:first-of-type {
                        white-space: nowrap;
                    }
                }
            }
        }

        .button-component:hover {
            box-shadow: none !important;
        }
    }

    .slide-down-enter-active,
    .slide-down-leave-active {
        transition: all 0.4s ease;
    }

    .slide-down-enter,
    .slide-down-leave-to {
        opacity: 0;
        transform: translateY(-10%);
    }
}
</style>
